import React from "react"
import MaskedInput from "react-text-mask"
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator"
import { graphql } from "gatsby"
import {
  Grid,
  Typography,
  MenuItem,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Box,
  Radio,
  RadioGroup,
} from "@material-ui/core"
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers"
import { Error, ThumbUp } from "@material-ui/icons"
import DateFnsUtils from "@date-io/date-fns"
import dutch from "date-fns/locale/nl"
import styled from "styled-components"
import {
  Section,
  SectionTitle,
  SectionText,
  RichText,
  Copyright,
  ActionButton,
  OutboundLink,
  Link,
  SiteTitle,
  BackButton,
  MenuDrawer,
  LandingEvent,
} from "../components"
import theme from "../theme"

// TODO: Refactor into smaller elements and separate files to keep an overview
const formName = "Inschrijfformulier"

const cellphoneMask = [
  "+",
  /\d/,
  /\d/,
  " ",
  /\d/,
  " ",
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
]

const CellphoneInput = (props) => {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={cellphoneMask}
    />
  )
}

const zipcodeMask = [/\d/, /\d/, /\d/, /\d/, " ", /[A-Za-z]/, /[A-Za-z]/]

const ZipcodeInput = (props) => {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={zipcodeMask}
    />
  )
}

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const ButtonGrid = styled(Grid)`
  margin-top: ${theme.spacing(4)}px;
  margin-bottom: 0px;
  text-align: center;
`

const TypographyGrid = styled(Grid)`
  margin-top: ${theme.spacing(4)}px;
`

const StretchedForm = styled(ValidatorForm)`
  display: flex;
  flex-grow: 1;
`

function isValidDate(date) {
  return (
    date &&
    Object.prototype.toString.call(date) === "[object Date]" &&
    !isNaN(date)
  )
}

export const InschrijvenPage = ({ data }) => {
  const {
    title,
    introduction,
    compact,
    introGroups,
    firstContact,
  } = data.allContentfulRegistrationForm.nodes[0]

  const { introMode, eventsText } = data.allContentfulHomePage.edges[0].node
  const [formData, setFormData] = React.useState(
    compact
      ? {
          firstName: "",
          lastName: "",
          email: "",
          cellphone: "",
          ...(firstContact ? { firstContact: "" } : {}),
          privacyPolicy: false,
          ...(introGroups ? { introGroup: "" } : {}),
        }
      : {
          initials: "",
          firstName: "",
          middleName: "",
          lastName: "",
          gender: "",
          birthday: null,
          email: "",
          cellphone: "",
          zipcode: "",
          city: "",
          street: "",
          houseNumber: "",
          institution: "",
          study: "",
          emergencyName: "",
          emergencyRelation: "",
          emergencyCellphone: "",
          allergiesAndDiet: "",
          remarks: "",
          privacyPolicy: false,
          ...(introGroups ? { introGroup: "" } : {}),
        }
  )

  const [submitted, setSubmitted] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [success, setSuccess] = React.useState(false)
  const [error, setError] = React.useState(false)

  const handleFormDataChange = (event) => {
    if (!event || !event.target) {
      return
    }

    event.persist()
    setFormData((oldFormData) => ({
      ...oldFormData,
      [event.target.name]: event.target.value,
    }))
  }

  const handleBirthdayChange = (birthday) => {
    setFormData((oldFormData) => ({
      ...oldFormData,
      birthday,
    }))
  }

  const handleCheckboxChange = (event) => {
    event.persist()
    setFormData((oldFormData) => ({
      ...oldFormData,
      [event.target.name]: event.target.checked,
    }))
  }

  const defaultProps = {
    disabled: loading,
  }

  const textFieldProps = (name) => ({
    ...defaultProps,
    variant: "outlined",
    fullWidth: true,
    onChange: handleFormDataChange,
    value: formData[name],
    name,
  })

  const requiredText = "Dit veld moet je invullen"
  const requiredProps = {
    required: true,
    validators: ["required"],
    errorMessages: [requiredText],
  }

  // This fires before validation, so even if the form is invalid
  const handleSubmitClick = () => {
    setSubmitted(true)

    // Trigger invalid date error in case it's not entered
    if (!compact && !formData.birthday) {
      handleBirthdayChange(" ")
    }
  }

  // This fires after validation, so after the form is valid
  const handleSubmit = (event) => {
    // If it's not a valid date, don't send the form and show the error
    if (!compact && !isValidDate(formData.birthday)) {
      return
    }

    // If the user didn't accept the privacy policy, don't send the form
    if (!formData.privacyPolicy) {
      return
    }

    setLoading(true)

    fetch("https://getform.io/f/3cad2b28-0ebe-4062-a516-9a96838195c4", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": formName, ...formData }),
    })
      .then(() => setSuccess(true))
      .catch(setError)
      .finally(() => setLoading(false))

    event.preventDefault()
  }

  const handleModalClose = () => setError(false)

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={dutch}>
      <Box minHeight="100vh" display="flex" flexDirection="column">
        <MenuDrawer />
        <SiteTitle title="Inschrijven" />
        {success ? (
          <Section color="dark" verticalSpacing={10} fillScreen>
            <BackButton to="../" color="secondary" />
            <Grid container justify="center">
              <Grid item xs={12} sm={10} md={6} xl={4}>
                <SectionTitle>
                  <Grid container spacing={1}>
                    <Grid item>
                      <ThumbUp fontSize="large" />
                    </Grid>
                    <Grid item>
                      <Typography variant="h3">Gelukt!</Typography>
                    </Grid>
                  </Grid>
                </SectionTitle>
                <SectionText>
                  Je inschrijving is verstuurd en wordt verwerkt! Heel erg leuk
                  dat je je hebt ingeschreven en je hoort snel van ons!
                </SectionText>
              </Grid>
              <ButtonGrid item xs={12}>
                <ActionButton to="/" component={Link}>
                  Terug
                </ActionButton>
              </ButtonGrid>
            </Grid>
          </Section>
        ) : (
          <React.Fragment>
            <Section
              color="dark"
              slant="background"
              slantCenter
              verticalSpacing={10}
            >
              <BackButton to="../" color="secondary" />
              <Grid container justify="center">
                {introMode ? (
                  <Grid item xs={12} sm={10} md={6} xl={4}>
                    <SectionTitle>{title}</SectionTitle>
                    <RichText>{introduction.json}</RichText>
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={10} md={6} xl={4}>
                    <SectionTitle>
                      Inschrijven = niet meer mogelijk
                    </SectionTitle>
                    <SectionText>
                      De inschrijvingen zijn momenteel gesloten.
                    </SectionText>
                  </Grid>
                )}
              </Grid>
            </Section>
            <Box
              display="flex"
              flexDirection="column"
              overflow="hidden"
              flexGrow={1}
            >
              {introMode ? (
                <StretchedForm
                  name={formName}
                  onSubmit={handleSubmit}
                  method="post"
                  action="https://getform.io/f/3cad2b28-0ebe-4062-a516-9a96838195c4"
                  noValidate
                >
                  <Section
                    slantCenter
                    slant="dark"
                    display="flex"
                    flexGrow={1}
                    flexDirection="column"
                  >
                    <Grid container justify="center">
                      <Grid item xs={12} sm={10} md={6} xl={4}>
                        <input type="hidden" name="bot-field" />
                        <input
                          type="hidden"
                          name="form-name"
                          value={formName}
                        />
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography variant="h5">
                              Persoonsgegevens
                            </Typography>
                          </Grid>
                          {compact ? (
                            <React.Fragment>
                              <Grid item xs={12} sm={12}>
                                <TextValidator
                                  {...textFieldProps("firstName")}
                                  {...requiredProps}
                                  label="Voornaam"
                                />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <TextValidator
                                  {...textFieldProps("lastName")}
                                  {...requiredProps}
                                  label="Achternaam"
                                />
                              </Grid>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <Grid item xs={12} sm={4}>
                                <TextValidator
                                  {...textFieldProps("initials")}
                                  {...requiredProps}
                                  label="Voorletters"
                                />
                              </Grid>
                              <Grid item xs={12} sm={8}>
                                <TextValidator
                                  {...textFieldProps("firstName")}
                                  {...requiredProps}
                                  label="Voornaam"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={4}>
                                <TextValidator
                                  {...textFieldProps("middleName")}
                                  label="Tussenvoegsels"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={8}>
                                <TextValidator
                                  {...textFieldProps("lastName")}
                                  {...requiredProps}
                                  label="Achternaam"
                                />
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <TextValidator
                                  {...textFieldProps("gender")}
                                  {...requiredProps}
                                  select
                                  label="Geslacht"
                                  name="gender"
                                >
                                  <MenuItem value="male">Man</MenuItem>
                                  <MenuItem value="female">Vrouw</MenuItem>
                                </TextValidator>
                              </Grid>
                              <Grid item xs={12} sm={8}>
                                <KeyboardDatePicker
                                  {...textFieldProps("birthday")}
                                  onChange={handleBirthdayChange}
                                  openTo="year"
                                  format="dd-MM-yyyy"
                                  label="Geboortedatum"
                                  views={["year", "month", "date"]}
                                  inputVariant="outlined"
                                  invalidDateMessage="Geen juiste datum"
                                  disableFuture
                                  required
                                />
                              </Grid>
                            </React.Fragment>
                          )}
                          <TypographyGrid item xs={12}>
                            <Typography variant="h5">
                              Contactgegevens
                            </Typography>
                          </TypographyGrid>
                          {compact ? (
                            <React.Fragment>
                              <Grid item xs={12} sm={12}>
                                <TextValidator
                                  {...textFieldProps("email")}
                                  {...requiredProps}
                                  label="E-mail"
                                />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <TextValidator
                                  {...textFieldProps("cellphone")}
                                  {...requiredProps}
                                  label="Telefoonnummer"
                                  InputProps={{
                                    inputComponent: CellphoneInput,
                                  }}
                                />
                              </Grid>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <Grid item xs={12} sm={6}>
                                <TextValidator
                                  {...textFieldProps("email")}
                                  {...requiredProps}
                                  label="E-mail"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextValidator
                                  {...textFieldProps("cellphone")}
                                  {...requiredProps}
                                  label="Telefoonnummer"
                                  InputProps={{
                                    inputComponent: CellphoneInput,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <TextValidator
                                  {...textFieldProps("zipcode")}
                                  {...requiredProps}
                                  label="Postcode"
                                  InputProps={{
                                    inputComponent: ZipcodeInput,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} sm={8}>
                                <TextValidator
                                  {...textFieldProps("city")}
                                  {...requiredProps}
                                  label="Plaats"
                                />
                              </Grid>
                              <Grid item xs={8}>
                                <TextValidator
                                  {...textFieldProps("street")}
                                  {...requiredProps}
                                  label="Straatnaam"
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <TextValidator
                                  {...textFieldProps("houseNumber")}
                                  {...requiredProps}
                                  label="Nummer"
                                />
                              </Grid>
                              <TypographyGrid item xs={12}>
                                <Typography variant="h5">
                                  Studiegegevens
                                </Typography>
                              </TypographyGrid>
                              <Grid item xs={12} sm={6}>
                                <TextValidator
                                  {...textFieldProps("institution")}
                                  {...requiredProps}
                                  select
                                  label="Onderwijsinstelling"
                                >
                                  <MenuItem value="eindhoven-university-of-technology">
                                    Technische Universiteit Eindhoven
                                  </MenuItem>
                                  <MenuItem value="fontys-eindhoven">
                                    Fontys Hogeschool Eindhoven
                                  </MenuItem>
                                  <MenuItem value="design-academy-eindhoven">
                                    Design Academy Eindhoven
                                  </MenuItem>
                                </TextValidator>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextValidator
                                  {...textFieldProps("study")}
                                  {...requiredProps}
                                  label="Studie"
                                />
                              </Grid>
                              <TypographyGrid item xs={12}>
                                <Typography variant="h5">Noodnummer</Typography>
                                <Typography>
                                  In geval van nood; wie kunnen we dan bellen?
                                </Typography>
                              </TypographyGrid>
                              <Grid item xs={12}>
                                <TextValidator
                                  {...textFieldProps("emergencyName")}
                                  {...requiredProps}
                                  label="Volledige naam"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextValidator
                                  {...textFieldProps("emergencyRelation")}
                                  {...requiredProps}
                                  label="Relatie tot jou"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextValidator
                                  {...textFieldProps("emergencyCellphone")}
                                  {...requiredProps}
                                  label="Telefoonnummer"
                                  InputProps={{
                                    inputComponent: CellphoneInput,
                                  }}
                                />
                              </Grid>
                              <TypographyGrid item xs={12}>
                                <Typography variant="h5">Overig</Typography>
                              </TypographyGrid>
                              <Grid item xs={12}>
                                <TextValidator
                                  {...textFieldProps("allergiesAndDiet")}
                                  label="Allergiën / Dieetwensen"
                                  helperText="Ergens allergisch voor? Vegetarisch? Dan houden we daar rekening mee."
                                  rows={3}
                                  multiline
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextValidator
                                  {...textFieldProps("remarks")}
                                  label="Opmerkingen"
                                  helperText="Nog iets waar we rekening mee moeten houden of heb je vragen?"
                                  rows={3}
                                  multiline
                                />
                              </Grid>
                            </React.Fragment>
                          )}
                          {firstContact ? (
                            <React.Fragment>
                              <TypographyGrid item xs={12}>
                                <Typography variant="h5">
                                  Hoe kwam je voor het eerst in contact met
                                  Demos?
                                </Typography>
                              </TypographyGrid>
                              <FormControl
                                error={submitted && !formData.firstContact}
                                component="fieldset"
                              >
                                <Box ml={1}>
                                  <Grid item xs={12} sm={12}>
                                    <RadioGroup
                                      {...textFieldProps("firstContact")}
                                    >
                                      <FormControlLabel
                                        value="Online"
                                        control={<Radio color="primary" />}
                                        label="Online"
                                      />
                                      <FormControlLabel
                                        value="Na Examen Dagen"
                                        control={<Radio color="primary" />}
                                        label="Na Examen Dagen"
                                      />
                                      <FormControlLabel
                                        value="Intro"
                                        control={<Radio color="primary" />}
                                        label="Intro"
                                      />
                                    </RadioGroup>
                                    {submitted && !formData.firstContact ? (
                                      <FormHelperText>
                                        Je moet één optie selecteren
                                      </FormHelperText>
                                    ) : (
                                      ""
                                    )}
                                  </Grid>
                                </Box>
                              </FormControl>
                            </React.Fragment>
                          ) : (
                            ""
                          )}
                          {introGroups ? (
                            <React.Fragment>
                              <TypographyGrid item xs={12}>
                                <Typography variant="h5">Introgroep</Typography>
                              </TypographyGrid>
                              <FormControl
                                error={submitted && !formData.introGroup}
                                component="fieldset"
                              >
                                <Box ml={1}>
                                  <Grid item xs={12} sm={12}>
                                    <RadioGroup
                                      {...textFieldProps("introGroup")}
                                    >
                                      <FormControlLabel
                                        value="Groep 1"
                                        control={<Radio color="primary" />}
                                        label="Groep 1"
                                      />
                                      <FormControlLabel
                                        value="Groep 2"
                                        control={<Radio color="primary" />}
                                        label="Groep 2"
                                      />
                                      <FormControlLabel
                                        value="Geen TU/e intro"
                                        control={<Radio color="primary" />}
                                        label="Ik doe niet mee aan de TU/e intro"
                                      />
                                    </RadioGroup>
                                    {submitted && !formData.introGroup ? (
                                      <FormHelperText>
                                        Je moet één optie selecteren
                                      </FormHelperText>
                                    ) : (
                                      ""
                                    )}
                                  </Grid>
                                </Box>
                              </FormControl>
                            </React.Fragment>
                          ) : (
                            ""
                          )}
                          <Box mt={5} mb={2}>
                            <FormControl
                              error={submitted && !formData.privacyPolicy}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    {...defaultProps}
                                    checked={formData.privacyPolicy}
                                    value={formData.privacyPolicy}
                                    onChange={handleCheckboxChange}
                                    name="privacyPolicy"
                                    color="primary"
                                    required
                                  />
                                }
                                label={
                                  <span>
                                    Ik heb de{" "}
                                    <OutboundLink
                                      href="https://dms.demos.nl/media/privacy/Privacy_Policy_E.S.V._Demos_v2.4.pdf"
                                      target="_blank"
                                    >
                                      Privacy Policy
                                    </OutboundLink>{" "}
                                    gelezen, ik begrijp wat hier in staat en ik
                                    ga hiermee akkoord.
                                  </span>
                                }
                              />
                              {submitted && !formData.privacyPolicy ? (
                                <FormHelperText>
                                  Je zult de Privacy Policy moeten accepteren
                                  als je je wilt inschrijven
                                </FormHelperText>
                              ) : (
                                ""
                              )}
                            </FormControl>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Box flexGrow={1} />
                    <ButtonGrid item xs={12}>
                      <ActionButton
                        {...defaultProps}
                        type="submit"
                        onClick={handleSubmitClick}
                      >
                        Schrijf me in!
                        {loading && <CircularProgress size={24} />}
                      </ActionButton>
                    </ButtonGrid>
                  </Section>
                </StretchedForm>
              ) : (
                <Section horizontalSpacing={3} slant="dark" slantCenter flipped>
                  <Grid container justify="center">
                    <Grid item xs={12} sm={10} md={6} xl={4}>
                      <Section horizontalSpacing={3} verticalSpacing={0}>
                        <SectionText>{eventsText.eventsText}</SectionText>
                      </Section>
                      <LandingEvent />
                    </Grid>
                  </Grid>
                </Section>
              )}
              <Section color="dark">
                <Copyright mt={0} />
              </Section>
            </Box>
            <Dialog open={error} onClose={handleModalClose}>
              <DialogTitle>
                <Grid container spacing={1}>
                  <Grid item>
                    <Error fontSize="large" color="error" />
                  </Grid>
                  <Grid item>Er ging iets fout</Grid>
                </Grid>
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Blijkbaar willen de internetgoden niet dat je je inschrijft
                  want we kregen de server niet te pakken. Probeer het even
                  opnieuw, mocht het dan nog steeds niet lukken kun je je ook
                  inschrijven door iemand van Demos aan te spreken, dan
                  schrijven we je handmatig in!
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleModalClose} color="primary">
                  Oké
                </Button>
              </DialogActions>
            </Dialog>
          </React.Fragment>
        )}
      </Box>
    </MuiPickersUtilsProvider>
  )
}

export const query = graphql`
  query getRegistrationFormData {
    allContentfulRegistrationForm {
      nodes {
        title
        introduction {
          json
        }
        compact
        introGroups
        firstContact
      }
    }
    allContentfulHomePage {
      edges {
        node {
          introMode
          eventsText {
            eventsText
          }
        }
      }
    }
  }
`

export default InschrijvenPage
